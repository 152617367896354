import React from "react";
import { Link, graphql } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import Img from "gatsby-image";
import Layout from "../components/Layout";
import moment from "moment";
import { Box, Button, Chip, Typography } from "@material-ui/core";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { ShareButtonRectangle, ShareBlockStandard } from "react-custom-share";
import SEO from "../components/SEO";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import PinterestIcon from "@material-ui/icons/Pinterest";
import EmailIcon from "@material-ui/icons/Email";
import LazyLoad from "react-lazy-load";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";

function ImageOrVideo(props) {
  if ("src" in props && props.src.length > 0) {
    const fileExtension = props.src.split(".").pop();
    if (fileExtension == "mp4" || fileExtension == "webm") {
      return (
        <video
          controls
          style={{
            // position: "absolute",
            // top: 0,
            // left: 0,
            width: "100%",
            height: "auto",
          }}
        >
          <source src={props.src} type={"video/" + fileExtension} />
        </video>
      );
    }
  }
  return <img {...props} />;
}

const useStyles = makeStyles((theme) => ({
  a: {
    color: "white",
    backgroundColor: "black",
  },
  article: {
    // lineHeight: 1.3,
    fontFamily: "Reem Kufi, Arial",
    color: theme.palette.primary.main,
    fontSize: "1.0rem",
    "& blockquote": {
      fontSize: "1.1rem",
      borderLeft: "3px solid #ffffff",
      backgroundColor: "#1c1c1c",
      padding: 1,
      paddingLeft: 12,
      // fontStyle: "italic",
      lineHeight: 1.35,

      // left: "-128px"
      // "content": "open-quote"
    },
  },
  chip: {
    marginRight: 4,
  },
}));

const Tags = ({ avatar, tags }) => {
  const classes = useStyles();

  return (
    <Box marginY={1}>
      {tags.map((tag) => {
        return (
          <Chip
            color="primary"
            variant="outlined"
            classes={{ root: classes.chip }}
            label={tag}
            key={tag}
            component={Link}
            to={`/tag/${tag}`}
            onClick={() => {}}
            avatar={avatar}
          />
        );
      })}
    </Box>
  );
};

export default function PostTemplate({ data, pageContext }) {
  const classes = useStyles();
  const {
    site: {
      siteMetadata: {
        siteUrl,
        steam_widget,
        components: {
          appbar: { games },
        },
      },
    },
  } = data;

  const { mdx } = data;

  const {
    frontmatter: { featuredImage, title, tags, id },
    body,
  } = mdx;

  const { frontmatter } = mdx;
  const { excerpt } = data;

  const { previousPath, nextPath, postDate } = pageContext;

  const shareBlockProps = {
    url: data.site.siteMetadata.siteUrl + "/posts/" + id,
    button: ShareButtonRectangle,
    buttons: [
      { network: "Twitter", icon: TwitterIcon },
      { network: "Facebook", icon: FacebookIcon },
      { network: "Email", icon: EmailIcon },
      {
        network: "Pinterest",
        icon: PinterestIcon,
      },
    ],
    text: frontmatter.title.toUpperCase(),
    longtext: frontmatter.description || excerpt,
  };

  return (
    <Layout>
      <SEO
        title={frontmatter.title.toUpperCase()}
        description={frontmatter.description || excerpt}
        meta={[
          {
            property: "og:image",
            content: `${data.site.siteMetadata.siteUrl}${featuredImage.childImageSharp.resize.src}`,
          },
          { property: "og:url", content: shareBlockProps.url },
        ]}
      />
      <Box flexGrow={1} width="100%" maxWidth={920} marginX="auto">
        <Box padding={2}>
          <Box display="flex" marginBottom={1}>
            <Typography
              color="textSecondary"
              variant="h4"
              style={{
                alignSelf: "center",
                // justifySelf: "center",
                fontFamily:
                  "Work Sans, -apple-system, BlinkMacSystemFont, Reem Kufi, sans-serif",
                textTransform: "uppercase",
              }}
            >
              {title}
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                flexGrow: 1,
              }}
            >
              <Typography variant="body2" color="textSecondary">
                {moment(postDate).format("LL")}
              </Typography>
              <Tags
                avatar={"icon" in games ? games["icon"] : undefined}
                tags={tags}
              />
            </div>
          </Box>
          <Img
            fluid={featuredImage.childImageSharp.fluid}
            style={{ borderRadius: 0 }}
          />

          <article className={classes.article}>
            <MDXProvider components={{ img: ImageOrVideo }}>
              <MDXRenderer>{body}</MDXRenderer>
            </MDXProvider>
          </article>
          <Box paddingBottom={2} style={{ filter: "grayscale(100%)" }}>
            <ShareBlockStandard {...shareBlockProps} />
          </Box>
          <LazyLoad>
            <iframe
              src={steam_widget}
              frameborder="0"
              width="100%"
              height="190"
            ></iframe>
          </LazyLoad>
          <Box display="flex">
            <Box flexGrow={1}>
              {previousPath && (
                <Button
                  component={Link}
                  to={previousPath}
                  variant="outlined"
                  color="primary"
                  size="large"
                >
                  <FaChevronLeft size={8} />
                  <Box marginLeft={0.5}>Previous</Box>
                </Button>
              )}
            </Box>
            {nextPath && (
              <Button
                component={Link}
                to={nextPath}
                variant="outlined"
                color="primary"
                size="large"
              >
                <Box marginRight={0.5}>Next</Box>
                <FaChevronRight size={8} />
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($postId: String!) {
    site {
      siteMetadata {
        siteUrl
        title
        steam_widget
        components {
          appbar {
            games {
              icon
            }
          }
        }
      }
    }

    mdx(frontmatter: { id: { eq: $postId } }) {
      body
      excerpt(pruneLength: 250)
      frontmatter {
        id
        title
        tags
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1280) {
              ...GatsbyImageSharpFluid
            }
            resize(width: 900, quality: 90) {
              src
            }
          }
        }
      }
    }
  }
`;
